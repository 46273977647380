import * as styles from './mission.module.css';

import Page from '../components/Page';

function Mission() {
  return (
    <Page
      title="Retrosquare - our mission"
      description="Simply put: the mission of Retrosquare is to help make people happy through deliberately wholesome applications, games, services, and more."
    >
      <section>
        <h2>Our Mission</h2>
        <p>
          We believe that the internet should be a realm of friendliness,
          goodwill, and wholesome activity. We assert that each individual can
          make a difference with their best efforts to be kind online.
        </p>
        <p>
          We believe that we are who we are because of those who have gone
          before us. We also believe that we can help shape future generations
          through our decisions, actions, and influence.
        </p>
        <p>
          We believe that wholesome entertainment can allow our minds to rest
          from our labors. It can enrich our lives and our interactions with
          friends and loved ones.
        </p>
        <p class={styles.statement}>
          The mission of Retrosquare is to help make people happy through
          deliberately wholesome applications, games, services, and more.
        </p>
      </section>
    </Page>
  );
}

export default Mission;
