import Page from '../components/Page';
import Typer from '../components/Typer';

import * as styles from './home.module.css';

import { PAGES } from '../constants';
import praiseworthyLogo from '../assets/praiseworthy.svg';
import journalousLogo from '../assets/journalous.svg';
import littleredcouchLogo from '../assets/littleredcouch.svg';

const TITLES = [
  'engineers',
  'artists',
  'data scientists',
  'game makers',
  'believers',
  'leaders',
  'hard workers',
  'teachers',
  'coaches',
  'writers',
  'statisticians',
];

function Home() {
  return (
    <Page>
      <div class={styles.home}>
        <p>
          <span class={styles.line}>We’re a group of</span>{' '}
          <Typer wordList={TITLES} />{' '}
          <span class={styles.line}>creating software</span>{' '}
          <span class={styles.line}>
            to <strong>make the world</strong>
          </span>{' '}
          <span class={styles.line}>
            a <strong>better</strong> place.
          </span>
        </p>
        <div class={styles.logos}>
          <a href={`${PAGES.PROJECTS}#praiseworthy`}>
            <img src={praiseworthyLogo} alt="Praiseworthy" />
          </a>
          <a href={`${PAGES.PROJECTS}#journalous`}>
            <img src={journalousLogo} alt="Journalous" />
          </a>
          <a href={`${PAGES.PROJECTS}#littleredcouch`}>
            <img src={littleredcouchLogo} alt="Little Red Couch Games" />
          </a>
        </div>
      </div>
    </Page>
  );
}

export default Home;
