import { useState, useEffect } from 'preact/hooks';

import * as styles from './typer.module.css';

const SPEED = 100;

function Typer({ wordList }) {
  const [word, setWord] = useState(0);
  const [letter, setLetter] = useState(0);
  const [reverse, setReverse] = useState(false);
  const [value, setValue] = useState('');

  useEffect(() => {
    const wordIndex = word % wordList.length;
    const wordLength = wordList[wordIndex].length;
    if (!reverse && letter < wordLength) {
      setValue((v) => v + wordList[wordIndex].charAt(letter));
      if (letter === wordLength - 1) {
        setReverse((r) => !r);
        setTimeout(() => {
          setLetter((i) => ++i);
        }, SPEED * 20);
      } else {
        setTimeout(() => {
          setLetter((i) => ++i);
        }, SPEED);
      }
    }
    if (reverse && letter >= 0) {
      setValue((v) => v.substring(0, letter));
      if (letter === 0) {
        setReverse((r) => !r);
        setWord((w) => ++w);
        setTimeout(() => {
          setLetter((i) => --i);
        }, SPEED * 10);
      } else {
        setTimeout(() => {
          setLetter((i) => --i);
        }, SPEED);
      }
    }
  }, [letter]);

  return <span class={styles.container}>{value}</span>;
}

export default Typer;
