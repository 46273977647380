import { useRoutes } from 'hookrouter';

import Home from './pages/Home';
import Mission from './pages/Mission';
import Projects from './pages/Projects';
import About from './pages/About';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import Disclaimer from './pages/Disclaimer';

const routes = {
  '/': () => <Home />,
  '/mission': () => <Mission />,
  '/projects': () => <Projects />,
  '/about': () => <About />,
  '/terms': () => <Terms />,
  '/privacy': () => <Privacy />,
  '/disclaimer': () => <Disclaimer />,
};

const App = () => {
  const routeResult = useRoutes(routes);
  return routeResult;
};

export default App;
