import * as styles from './badge.module.css';

function Badge({ bgColor, fgColor, children }) {
  return (
    <span style={{ background: bgColor, color: fgColor }} class={styles.badge}>
      {children}
    </span>
  );
}

export default Badge;
