import Page from '../components/Page';

function About() {
  return (
    <Page
      title="Retrosquare - about us"
      description="Retrosquare is a group of skilled multidisciplinary individuals who are passionate about helping others."
    >
      <section>
        <h2>About Us</h2>
        <p>
          Retrosquare was founded by a group of software engineers and business
          professionals with various backgrounds. Our goal as a team is to use
          our expertise in web development, data analysis, game development,
          etc. for good to help better the world and those who share it with us.
        </p>
        <p>
          With almost a century of combined experience, we are confident that we
          can succeed in helping the users of our software and services live
          happier lives. This is our passion and we are excited to share our
          work with the world.
        </p>
        <p>
          Join us and help us make the world a better place in any way you know
          how, because we know that we can all individually add our efforts to
          the equation of goodness.
        </p>
      </section>
    </Page>
  );
}

export default About;
