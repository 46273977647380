.home {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 0 3rem;
  flex-direction: column;
}

.home p {
  font-size: 4rem;
  font-weight: 300;
  text-align: right;
}

.home p > strong {
  font-weight: 700;
}

.line {
  display: block;
}

.logos {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10rem;
  margin-top: 3rem;
}

.logos a {
  border-bottom: none;
}

.logos a:hover {
  border-bottom: none;
}

.logos img {
  height: 7.5rem;
  width: auto;
}

/* Medium */
@media (max-width: 90rem) {
  .home {
    align-items: flex-start;
    padding: 0 3rem;
  }

  .home p {
    text-align: left;
  }

  .logos {
    gap: 7.5rem;
  }
}

/* Small */
@media (max-width: 52.125rem) {
  .home {
    align-items: center;
    padding: 0 1rem;
  }

  .home p {
    font-size: calc(100vw * 0.08);
    text-align: center;
  }

  .logos {
    flex-direction: column;
    gap: 5rem;
    margin-bottom: 3rem;
  }

  .logos img {
    max-height: 7.5rem;
    height: auto;
    width: 100%;
  }
}
