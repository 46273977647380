@keyframes borderBlink {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: black;
  }
}

.container {
  display: inline-block;
  border-right: 2px solid #000;
  animation: borderBlink 1.5s step-end infinite;
}
