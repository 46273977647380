import { useEffect } from 'preact/hooks';
import { A } from 'hookrouter';

import * as styles from './page.module.css';

import { DEFAULT_TITLE, DEFAULT_DESCRIPTION, PAGES } from '../constants';
import rsIcon from '../assets/Icon-b.svg';

function Page({ title, description, children }) {
  const year = new Date().getFullYear();

  useEffect(() => {
    document.title = title || DEFAULT_TITLE;
    document
      .querySelector('meta[name="description"]')
      .setAttribute('content', description || DEFAULT_DESCRIPTION);
  }, [title, description]);

  return (
    <div class={styles.page}>
      <header class={styles.header}>
        <div>
          <A aria-label="Retrosquare" class={styles.logo} href={PAGES.HOME}>
            <h1>&nbsp;</h1>
          </A>
          <input class={styles.menuBtn} type="checkbox" id="menu-btn" />
          <label class={styles.menuIcon} for="menu-btn">
            <span class={styles.navicon}></span>
          </label>
          <ul class={styles.menu}>
            <li>
              <A href={PAGES.MISSION}>our mission</A>
            </li>
            <li>
              <A href={PAGES.PROJECTS}>projects</A>
            </li>
            <li>
              <A href={PAGES.ABOUT}>about us</A>
            </li>
          </ul>
        </div>
      </header>
      <main class={styles.main}>
        <div>
          {children}
          <footer class={styles.footer}>
            <span class={styles.line}>
              &copy; Copyright {year}{' '}
              <A class={styles.rsIcon} href={PAGES.HOME}>
                <img
                  alt="Retrosquare LLC"
                  title="Copyright Retrosquare LLC"
                  src={rsIcon}
                />
              </A>
            </span>
            <span class={styles.line}>
              {' • '}
              <A href={PAGES.TERMS}>Terms of Use</A>
              {' • '}
              <A href={PAGES.PRIVACY}>Privacy Policy</A>
              {' • '}
            </span>
            <span class={styles.line}>
              <A href={PAGES.DISCLAIMER}>Disclaimer</A>
            </span>
          </footer>
        </div>
      </main>
    </div>
  );
}

export default Page;
