export const PAGES = {
  HOME: '/',
  MISSION: '/mission',
  PROJECTS: '/projects',
  ABOUT: '/about',
  TERMS: '/terms',
  PRIVACY: '/privacy',
  DISCLAIMER: '/disclaimer',
};

export const DEFAULT_TITLE = 'Retrosquare - make the world better';
export const DEFAULT_DESCRIPTION =
  'A group of engineers creating software to make the world a better place.';
