import Page from '../components/Page';
import Badge from '../components/Badge';

import * as styles from './projects.module.css';

function Projects() {
  return (
    <Page
      title="Retrosquare - projects"
      description="The applications, games, services and other projects currently being worked on by the engineers at Retrosquare."
    >
      <section>
        <h2>Projects</h2>
        <p>
          Retrosquare is always actively conceptualizing new ways to fulfill our
          desire to make the world a better place with the production of high
          quality software and services. The following is a list of projects
          currently in development:
          <a id="praiseworthy" />
        </p>
        <div class={styles.project}>
          <h3>Praiseworthy</h3>
          <p>
            In a world full of anger&mdash;where everyone is so
            negative&mdash;we thought we could use a tool to share a whole lot
            of love and positivity. Praiseworthy is a messaging app that allows
            users to share positivity in a way that is meaningful and impactful.
          </p>
          <p>
            Website:{' '}
            <a href="https://praiseworthy.app" target="_blank">
              https://praiseworthy.app
            </a>
            <a id="journalous" />
          </p>
        </div>
        <div class={styles.project}>
          <h3>Journalous</h3>
          <p>
            Have you ever wondered why some people use social media to express
            super personal thoughts? We believe it helps them cope with certain
            things in their life, but sometimes the stuff they post belongs in a
            more intimate setting than wide open on the internet. This is why we
            are creating what we like to call an antisocial-smart-journal. This
            journal will allow people to express what's on their mind in a
            familiar social-media-like setting, while remaining secure, private,
            and isolated from the rest of the world.
          </p>
          <p>
            Website:{' '}
            <a href="https://journalo.us" target="_blank">
              https://journalo.us
            </a>{' '}
            &mdash;{' '}
            <a href="https://forms.gle/Jd5Xqf4h5ksSeTdv9" target="_blank">
              Beta Now Open!
            </a>
            <a id="littleredcouch" />
          </p>
        </div>
        <div class={styles.project}>
          <h3>Little Red Couch</h3>
          <p>
            Games should be beautiful to the eye. They should warrant the love
            of their players. And they should unlock the mind. Little Red Couch
            is a game studio that focuses primarily on these three pillars.
          </p>
          <p>
            Website:{' '}
            <a href="https://littleredcouch.com" target="_blank">
              https://littleredcouch.com
            </a>
          </p>
        </div>
        <div class={styles.project}>
          <h3>
            Proove.it{' '}
            <Badge bgColor="#000" fgColor="#fff">
              Pet Project
            </Badge>
          </h3>
          <p>
            Don't just say it; proove it! Proove.it is an image sharing website
            that allows its users to share images quickly and effectively. It
            was originally created during a 24-hour hackathon and has undergone
            metamorphosis on several occasions since then.
          </p>
          <p>
            Website:{' '}
            <a href="https://proove.it" target="_blank">
              https://proove.it
            </a>
          </p>
        </div>
      </section>
    </Page>
  );
}

export default Projects;
