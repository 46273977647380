.page {
  background: #fff;
  display: flex;
  min-height: 100vh;
  min-width: 90rem;
}

.header {
  background: #000;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: sticky;
  top: 0;
  min-width: 22.5rem;
  width: calc(22.5rem + (100vw - 90rem) / 2);
}

.header > div {
  align-self: flex-end;
  height: 100vh;
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 3rem;
}

.logo {
  width: 100%;
  text-decoration: none;
  border-bottom: none;
}

.logo:hover {
  border-bottom: none;
}

.header h1 {
  margin: 0;
  height: 2.5rem;
  width: 100%;
  background: url('../assets/Long-w.svg') right 2rem top 0 / contain no-repeat;
}

.main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 67.5rem;
  width: calc(67.5rem + (100vw - 90rem) / 2);
}

.main > div {
  align-self: flex-start;
  min-height: 100vh;
  width: 67.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer {
  align-self: flex-end;
  padding: 2rem 3rem;
}

.footer .rsIcon > img {
  height: 1.25rem;
  width: 1.25rem;
  display: inline-block;
  background: white;
  vertical-align: sub;
}

.footer .rsIcon {
  border-bottom: none;
}

.menuIcon {
  display: none;
}

.menuBtn {
  display: none;
}

.menu {
  padding: 0;
  margin: 0;
  list-style: none;
  margin-top: 1rem;
  text-align: right;
  transition: all 0.2s ease-out;
  max-height: none;
  overflow: hidden;
}

.menu a,
.menu a:hover {
  color: #fff;
  border-bottom: none;
  white-space: nowrap;
}

.menu li {
  line-height: 3;
  padding-right: 2rem;
}

.menu li:hover {
  background: #fff;
}

.menu li:hover a {
  color: #000;
}

/* Medium */
@media (max-width: 90rem) {
  .page {
    min-width: 52.125rem;
    flex-direction: column;
  }

  .header {
    flex-direction: row;
    height: 4.5rem;
    min-width: auto;
    width: 100%;
  }

  .header > div {
    align-self: flex-start;
    height: 100%;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0;
  }

  .header h1 {
    margin: 0;
    height: 2.5rem;
    width: 50%;
    background: url('../assets/Short-w.svg') left 3rem top 0 / contain no-repeat;
  }

  .main {
    min-height: calc(100vh - 4.5rem);
    min-width: auto;
    width: 100%;
  }

  .main > div {
    width: 100%;
    min-height: calc(100vh - 4.5rem);
  }

  .footer {
    align-self: flex-start;
  }

  .menuIcon {
    cursor: pointer;
    display: inline-block;
    padding: 1.75rem 3rem 1.75rem 1.5rem;
    position: relative;
    user-select: none;
  }

  .navicon {
    background: #fff;
    display: block;
    height: 0.3125rem;
    position: relative;
    transition: background 0.2s ease-out;
    width: 2rem;
  }

  .navicon:before,
  .navicon:after {
    background: #fff;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all 0.2s ease-out;
    width: 100%;
  }

  .navicon:before {
    top: 0.75rem;
  }

  .navicon:after {
    top: -0.725rem;
  }

  .menu {
    position: absolute;
    right: 0;
    top: 4.5rem;
    background: #000;
    margin-top: 0;
    font-size: 1.5rem;
    max-height: 0;
    transition: all 0.2s ease-out;
  }

  .menuBtn:checked ~ .menu {
    max-height: 13.5rem;
    padding-bottom: 15rem;
    transition: all 0.2s ease-out;
  }

  .menu li {
    padding-left: 2rem;
  }

  .menu a {
    color: #fff;
    border-bottom: 1px dotted;
  }

  .menu li:hover {
    background: #000;
  }

  .menu li:hover a {
    color: #fff;
    border-bottom: 1px solid;
  }

  .menuBtn:checked ~ .menuIcon .navicon {
    background: transparent;
  }

  .menuBtn:checked ~ .menuIcon .navicon:before {
    transform: rotate(-45deg);
  }

  .menuBtn:checked ~ .menuIcon .navicon:after {
    transform: rotate(45deg);
  }

  .menuBtn:checked ~ .menuIcon:not(.steps) .navicon:before,
  .menuBtn:checked ~ .menuIcon:not(.steps) .navicon:after {
    top: 0;
  }
}

/* Small */
@media (max-width: 52.125rem) {
  .page {
    min-width: auto;
    flex-direction: column;
  }

  .header {
    height: 4rem;
  }

  .header h1 {
    background: url('../assets/Icon-w.svg') left 1rem top 0 / contain no-repeat;
  }

  .menuIcon {
    padding: 1.75rem 1rem 1.75rem 1rem;
  }

  .menu {
    top: 4rem;
  }

  .main {
    min-height: calc(100vh - 4rem);
  }

  .main > div {
    min-height: calc(100vh - 4rem);
  }

  .footer {
    align-self: center;
    padding: 2rem 1rem;
  }

  .footer > .line {
    display: block;
    text-align: center;
  }
}
